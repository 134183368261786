<template>
  <div class="container-fluid">
    <div class="bg"></div>
    <div class="row L1">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h1>Timelapse Toolbox</h1>
            <p>强大的文件 / 文件夹重命名工具，以及一键文件夹整理</p>
            <div class="btnbox">
              <a class="btn" href="https://pan.baidu.com/s/1mZxVBvqcE2sKF2ULSYoMmQ?pwd=xxs8"  target="_blank">立即下载</a>
            </div>
            <div class="btnbox visible-xs">
              <a class="btn" href="javascript:void(0);">请前往桌面端下载</a>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="screenshot">
              <img  draggable="false" class="img-responsive" src="@/assets/img/timelapsetoolbox/1.gif" alt="" srcset="" >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row L2">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="page-header">
              <h1>主要功能</h1>
            </div>
            <h3>修改RAW的拍摄时间<span>防止相机时钟电池没电导致拍摄时间错误</span></h3>
            <h3>可完全自定义的批量重命名<span>自定义模板，可将拍摄时间写入文件名，批量更改后缀名</span></h3>
            <h3>自动整理混乱的文件夹<span>自动根据拍摄日期、拍摄参数来一键分类文件夹</span></h3>
            <h3>文件夹也能批量操作<span>批量替换、插入、删除字符</span></h3>

            
            <div class="page-header">
              <h1>更新日志</h1>
            </div>
            <p>20240929 1.1.4 修复白屏错误，修复部分情况下无法读取的问题</p>
            <p>20240912 1.1.3 优化删除字符工具中从扩展名尾删除文字的逻辑</p>
            <p>20240731 1.1.2 优化超长文件名的显示</p>
            <p>20240719 1.1.0 增加文本替换功能，现在可以识别所有类型的文件和文件夹</p>
            <p>20231216 1.0.0 第一版</p>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'RAW2DNG',
  props: {
    
  },
  computed:{

  },
  data(){
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted(){
    
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
  .container-fluid{
    width: 100%;
    padding: 0;
    margin: 0;
    .bg{
      width: 100%;
      height: 800px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      background-image: linear-gradient(25deg, #cc584f, #d5784b, #dd9546, #e3b23e);
    }
    .row{
      margin: 0;
      padding: 0;
    }
    .L1{
      color: #fff;
      padding-top: 100px;
      height: 1200px;
      h1{
        font-size: 64px;
        font-weight: 300;
        margin-top: 70px;
        @media (max-width: 970px) { 
          margin: 100px 0 50px;
        }
      }
      p{
        font-weight: 300;
      }
      .btnbox{
        text-align: center;
        margin: 60px 0;
        .btn{
          font-size: 16px;
          line-height: 1;
          padding: 8px 20px 8px;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 20px;
          margin: 10px 0;
          display: inline-block;
          margin-right: 10px;
          &:hover{
            color: #e3b23e;
            background-color: #fff;
          }
        }
        a{
          color: #fff;
        }
      }
      .screenshot{
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding-bottom: 50px;
        img{
          width: 80%;
          border-radius: 10px;
          display: inline-block;
          box-shadow: 0 0 30px rgba(0,0,0,0.2);
        }
      }
    }
    .L2{
      p{
        line-height: 1.75;
        margin-bottom: 0;
      }
      
      h3{
        span{
          display: block;
          font-size: 16px;
          font-weight: normal;
          color: #999;
          margin-top: 5px;
        }
      }
    }
    .qa{
      .q{
        margin-bottom: 0px;
      }
      .a{
        text-indent: -1.3em;
        margin-left: 1.3em;
      }
    }
    @media screen and (max-width:900px){
      .L1{
        height: 800px;
        .btnbox{
          display: none;
        }
      }
      .L2{
        .panel{
          .panel-inbox{
            padding: 10px 20px;
          }
          h4{
            font-size: 28px;
          }
        }
      }
    }
  }
</style>